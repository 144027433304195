<template>
  <dash-page-new
      :root="$t('Projects')"
      navigation-return-route="project-manager"
      :subtitle="$store.state.settings.pageData.subtitle || $t('Project')"
      :title="$store.state.settings.pageData.title || $t('ProjectViewer')"
      :icon="$store.state.settings.pageData.icon || 'mdi-cogs'"
      :filters="$store.state.settings.pageData.filters || {}"
      :header-route="$store.state.settings.pageData.headerRoute || null"
      :filters-input="$store.state.settings.filtersData || []"
      :side-navigation="navigationSelect"
      @filter="$store.state.settings.filtersData = $event"
      :navigation-route="`project-manager/projects/${uuid}`"

  >

    <template #header_action.desktop>
      <div class="d-flex align-center">

        <ft-select
            v-if="($store.state.settings.pageData.headerAction || !!$store.state.settings.pageData.headerDropdownActions) && ($store.state.settings.pageData.headerActionCondition === null || $store.state.settings.pageData.headerActionCondition)"
            :items="$store.state.settings.pageData.headerDropdownActions || []">
          <ws-button
              @click="!$store.state.settings.pageData.headerDropdownActions ? $store.state.settings.pageData.headerAction() : () => {}"
              :label-raw="$store.state.settings.pageData.headerActionText"
              :click-stop="!$store.state.settings.pageData.headerDropdownActions"
              :icon="!!$store.state.settings.pageData.headerDropdownActions ? 'mdi-menu-down' : null"
          />
        </ft-select>

      </div>
    </template>

    <template #default>
      <router-view></router-view>
    </template>


  </dash-page-new>


</template>

<script>
export default {
  name: "projectViewer",
  props : {
    uuid : {
      type : String,
      default : ''
    }
  },
  data() {
    return {
      entity : {
        name : ''
      },
      items : []
    }
  },
  computed : {
    navigationSelect() {
      return [
        { text : this.$t('projects.editor.title') , value : ''             , path : '' , icon : 'mdi-pencil' },
        // { text : 'Dashboard' ,     value : 'dashboard'    , path : 'dashboard' , icon : 'mdi-view-dashboard-outline' },
        { text : this.$t('Participants') ,  value : 'participants' , path : 'participants' , icon : 'mdi-account-multiple' },
        // { text : 'Meetings' ,      value : 'meetings'     , path : 'meetings' , icon : 'mdi-video' },
        // { text : 'Files' ,         value : 'files'        , path : 'files' , icon : 'mdi-folder' }
      ]
    }
  }
}
</script>

<style scoped>

</style>